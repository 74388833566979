import React from "react";
import { Switch, Route } from "react-router-dom";
import { PayScreen } from "../components/pays/PayScreen";

export const PayRouter = () => {
  return (
    <div className="auth__main">
      <Switch>
        <Route
          exact
          path="/payments/:id_parent/:id_kid"
          component={PayScreen}
        />
      </Switch>
    </div>
  );
};
