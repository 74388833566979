import React from "react";
import { Switch, Route } from "react-router-dom";
import { DaycareRegisterScreen } from "../components/daycares/DaycareRegisterScreen";
import { InvitationScreen } from "../components/daycares/InvitationScreen";

export const DaycareRouter = () => {
  return (
    <div className="auth__main">
      <Switch>
        <Route exact path="/daycare/invitation/" component={InvitationScreen} />
        <Route
          exact
          path="/daycare/register/"
          component={DaycareRegisterScreen}
        />
      </Switch>
    </div>
  );
};
