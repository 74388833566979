import React, { useState } from "react";
import { Link } from "react-router-dom";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import arrow from "././../../assets/images/flechas/Flecha_izquierda.svg";
import { BoxesModal } from "../boxes/BoxesModal";
import { useForm } from "../../hooks/useForm/useForm";
import { useDispatch } from "react-redux";
import validator from "validator";
import { removeError, setError } from "../../actions/ui";

export const RecoveryScreen = () => {
  let [state, setState] = useState({
    show: false,
    data: {},
  });

  // const [code, setCode] = useState("0000");

  const dispatch = useDispatch();

  const [formRegisterValues, handleRegisterInputChange] = useForm({
    email: "",
  });

  let { email } = formRegisterValues;

  const isFormValid = () => {
    if (email.trim().length === 0) {
      dispatch(
        setError("*El correo electrónico es obligatorio", "emailRequired")
      );
      return false;
    } else if (!validator.isEmail(email)) {
      dispatch(
        setError("*Este correo electrónico no es válido!", "emailInvalid")
      );
      return false;
    }
    dispatch(removeError());
    return true;
  };

  const sendCode = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Hola Mundo otra vez");
      fetch(`http://localhost:1337/acuarelausers/recover/password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mail: email }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setState((state.show = true));
            setState((state.data = data));
          } else console.log("*Revise los datos ingresados.");
        });
    }
  };

  const leftStyle = {
    backgroundImage: `url(${bg})`,
  };
  return (
    <div className="login">
      <div className="left" style={leftStyle}>
        <div className="txt">
          <h1>Ayudamos a los daycares a cuidar el futuro</h1>
          <p>
            Reconocemos que la industria del cuidado infantil necesita un
            software que sea amigable y fácil de usar, y al mismo tiempo lo
            suficientemente poderoso como para ayudar a los propietarios y
            operadores de cuidado infantil a manejar las muchas
            responsabilidades y tareas que surgen cada día.
          </p>
          <button className="btn noFill white">Ver más</button>
        </div>
      </div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        <form onSubmit={sendCode} autoComplete="off">
          <Link to="/auth/home" className="backArrow">
            <img src={arrow} alt="back" width="32" height="32" />
          </Link>
          <h2>Ingresa tu e-mail o número para recuperar contraseña</h2>
          <span>
            <label>
              Correo electrónico:{" "}
              <Link to="/auth/recovery-password-phone">
                Recuperar con número
              </Link>
            </label>
            <input
              id="email"
              name="email"
              placeholder="Correo electrónico"
              type="email"
              value={email}
              onChange={handleRegisterInputChange}
            />
          </span>
          <Link to="/auth/home" className="bold linkAuth">
            Cancelar
          </Link>
          <button type="submit" className="btn fill">
            Enviar
          </button>
        </form>
      </div>
      <BoxesModal isOpen={true} state={state} />
    </div>
  );
};
