import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RegisterScreen } from "../components/auth/RegisterScreen";
import { RegisterPhoneScreen } from "../components/auth/RegisterPhoneScreen";
import { NewPasswordScreen } from "../components/auth/NewPasswordScreen";
import { RecoveryScreen } from "../components/auth/RecoveryScreen";
import { RecoveryPhoneScreen } from "../components/auth/RecoveryPhoneScreen";
import { HomeScreen } from "../components/auth/HomeScreen";
import { PayScreen } from "../components/pays/PayScreen";

export const AuthRouter = () => {
  return (
    <div className="auth__main">
      <Switch>
        <Route exact path="/auth/home" component={HomeScreen} />
        <Route exact path="/auth/register/:id" component={RegisterScreen} />
        <Route
          exact
          path="/auth/register-phone/:token"
          component={RegisterPhoneScreen}
        />
        <Route
          exact
          path="/auth/change-password/:token"
          component={NewPasswordScreen}
        />
        <Route
          exact
          path="/auth/recovery-password"
          component={RecoveryScreen}
        />
        <Route
          exact
          path="/auth/recovery-password-phone"
          component={RecoveryPhoneScreen}
        />
        <Route exact path="/payments" component={PayScreen} />
        <Redirect to="/auth/home" />
      </Switch>
    </div>
  );
};
