import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PayPalBtn } from "../paypalComp";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const PayScreen = () => {
  useDocumentTitle("Pagos - Acuarela");
  const { id_parent, id_kid } = useParams();
  const [parent, setParent] = useState({});
  const [kid, setKid] = useState({});
  const [inscription, setInscription] = useState({});
  const [plan, setPlan] = useState(0);
  const [ready, setReady] = useState(false);
  const [validate, setValidate] = useState(false);
  const [payApproval, setPayApproval] = useState(false);
  const [allData, setAllData] = useState({});

  let url = "https://api-m.paypal.com";

  const getInfoParent = async () => {
    setValidate(true);
    const parentData = await fetch(
      `https://acuarelacore.com/api/acuarelausers/${id_parent}`
    )
      .then((res) => res.json())
      .then((data) => data);

    setParent({ ...parentData });
    console.log(parentData);
    let kid = parentData.children.find((kid) => kid.id === id_kid);
    setKid({ ...kid });
    console.log(kid.inscripcion);
    const inscriptionData = await fetch(
      `https://acuarelacore.com/api/inscripciones/${kid.inscripcion}`
    )
      .then((res) => res.json())
      .then((data) => data);
    console.log({ inscriptionData });
    setInscription({ ...inscriptionData });
    let credentials = btoa(
      `${parentData.daycare.paypal.client_id}:${parentData.daycare.paypal.secret}`
    );
    const daycaretoken = await getTokenDaycare(credentials);
    console.log(daycaretoken);
    const productCreated = await createProduct(
      daycaretoken.access_token,
      parentData,
      kid
    );
    console.log(productCreated);
    const planCreated = await createPlan(
      productCreated.id,
      daycaretoken.access_token,
      inscriptionData,
      parentData,
      kid
    );
    console.log(planCreated);
    setPlan(planCreated.id);
    setReady(true);
  };
  const getTokenDaycare = async (credentials) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Basic ${credentials}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("ignoreCache", "true");
    urlencoded.append("return_authn_schemes", "true");
    urlencoded.append("return_client_metadata", "true");
    urlencoded.append("return_unconsented_scopes", "true");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const TOKEN = await fetch(url + "/v1/oauth2/token", requestOptions)
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("error", error));
    return TOKEN;
  };
  const createProduct = async (accessToken, parent, kid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      name: "Pago Daycare",
      description: `pago realizado por: ${parent.name} ${parent.lastname}, padre de ${kid.name}`,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const product = await fetch(url + "/v1/catalogs/products", requestOptions)
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("error", error));
    return product;
  };
  const createPlan = async (
    product_id,
    accessToken,
    inscriptionInfo,
    parent,
    kid
  ) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Prefer", "return=representation");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      product_id: product_id,
      name: "Plan pago daycare",
      description: `pago realizado por: ${parent.name} ${parent.lastname}, padre de ${kid.name}`,
      status: "ACTIVE",
      billing_cycles: [
        {
          frequency: {
            interval_unit:
              inscriptionInfo.payment.time === "Mensual" ? "MONTH" : "YEAR",
            interval_count: 1,
          },
          tenure_type: "REGULAR",
          sequence: 1,
          pricing_scheme: {
            fixed_price: {
              value: inscriptionInfo.payment.price,
              currency_code: "USD",
            },
          },
        },
      ],
      payment_preferences: {
        auto_bill_outstanding: true,
        setup_fee: {
          value: 0,
          currency_code: "USD",
        },
        setup_fee_failure_action: "CONTINUE",
        payment_failure_threshold: 3,
      },
      taxes: {
        percentage: "0",
        inclusive: false,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const PLAN = await fetch(url + "/v1/billing/plans", requestOptions)
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("error", error));

    return PLAN;
  };
  // PAYPAL
  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: plan,
    });
  };
  const paypalOnError = (err) => {
    console.log("Error");
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    console.log("Payapl approved");
    console.log(data);
    console.log(data.subscriptionID);
    console.log(detail);
    setAllData({
      data,
      detail,
    });
    setPayApproval(true);
  };
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };
  return (
    <div className="pay">
      <div className="register">
        <div className="left" style={leftStyle}></div>
        <div className="right">
          <img src={supDer_mancha} alt="supDer" className="supDer" />
          {payApproval ? (
            <div
              className="center"
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingRight: 40,
                paddingLeft: 40,
              }}
            >
              <h1
                style={{
                  marginBottom: 15,
                  fontFamily: "Proxima Nova",
                  fontWeight: 700,
                  fontSize: 30,
                  color: "#140A4C",
                  textAlign: "center",
                }}
              >
                Pago aprobado
              </h1>
              <p
                style={{
                  fontFamily: "Proxima Nova",
                  fontWeight: 700,
                  fontSize: 15,
                  color: "#140A4C",
                  marginBottom: 15,
                }}
              >
                Los pagos automáticos a tu daycare fueron activados
                correctamente, estos son los detalles de tu primer pago:
              </p>
              <ul className="details">
                <li>
                  <b>Número de orden: </b>
                  {allData.data.orderID}
                </li>
                <li>
                  <b>ID Suscripción:</b>
                  {allData.data.subscriptionID}
                </li>
                <li>
                  <b>Valor pagado:</b>
                  {formatter.format(inscription.payment.price)}
                </li>
              </ul>
            </div>
          ) : (
            <div
              className="center"
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <h1
                style={{
                  marginBottom: 15,
                  fontFamily: "Proxima Nova",
                  fontWeight: 700,
                  fontSize: 30,
                  color: "#140A4C",
                  textAlign: "center",
                }}
              >
                Activar Suscripción
              </h1>
              <p
                style={{
                  fontFamily: "Proxima Nova",
                  fontWeight: 700,
                  fontSize: 15,
                  color: "#140A4C",
                  marginBottom: 15,
                }}
              >
                Ya esta todo listo para realizar tu suscripción
              </p>
              {!ready && (
                <button
                  type="button"
                  onClick={getInfoParent}
                  style={{
                    /* Auto layout */
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 12,
                    height: 39,
                    background: "#0CB5C3",
                    borderRadius: 8,
                    color: "#fff",
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  {validate ? "Validando..." : "Validar información"}
                </button>
              )}
              {ready && (
                <div className="infoPay">
                  <h2>Información de la suscripción</h2>
                  <p>
                    <b>Nombre padre:</b>
                    <span>{`${parent.name} ${parent.lastname}`}</span>
                  </p>
                  <p>
                    <b>Nombre niño:</b>
                    <span>{kid.name}</span>
                  </p>
                  <p>
                    <b>Daycare:</b>
                    <span>{parent.daycare.name}</span>
                  </p>
                  <p>
                    <b>Total a pagar:</b>
                    <span>{inscription.payment.price}</span>
                  </p>
                  <p>
                    <b>Periodicidad:</b>
                    <span>{inscription.payment.time}</span>
                  </p>
                  <PayPalBtn
                    clientID={parent.daycare.paypal.client_id}
                    amount={inscription.payment.price}
                    currency="USD"
                    createSubscription={paypalSubscribe}
                    onApprove={paypalOnApprove}
                    catchError={paypalOnError}
                    onError={paypalOnError}
                    onCancel={paypalOnError}
                  />
                </div>
              )}
            </div>
          )}
          <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        </div>
      </div>
    </div>
  );
};
