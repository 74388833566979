export const types = {
  login: "[Auth] Login",
  logout: "[Auth] Logout",
  // AUTHENTICATION
  authCheckingFinish: "[Auth] Finish Checking login state",
  authStartLogin: "[Auth] Start Login",
  authLogin: "[Auth] Login",
  authStartRegister: "[Auth] Start register",
  authStartTokenRenew: "[Auth] Start token renew",
  authLogout: "[Auth] Logout",
  //   SET ERRORS
  uiSetError: "[UI] Set Error",
  uiRemoveError: "[UI] Remove Error",
  //   START LOADING
  uiStartLoading: "[UI] Start loading",
  uiFinishLoading: "[UI] Finish loading",
  //   MODALS
  uiOpenModal: "[UI] OPen modal",
  uiCloseModal: "[UI] Close modal",
  uiOpenCalendarModal: "[UI] OPen calendar modal",
  uiCloseCalendarModal: "[UI] Close calendar modal",
  // MODAL INVITATION
  uiInvitationOpenModal: "[UI] OPen modal",
  uiInvitationCloseModal: "[UI] Close modal",
  // EVENTS
  eventStartNew: "[EVENT] eventStartNew",
};
