import { types } from "../types/types";

const initialState = {
  modalOpen: false,
  CalendarModalOpen: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiSetError:
      return {
        ...state,
        msgError: action.payload,
        errorType: action.errorType,
      };
    case types.uiRemoveError:
      return {
        ...state,
        msgError: null,
        errorType: null,
      };
    case types.uiStartLoading:
      return {
        ...state,
        loading: true,
      };
    case types.uiFinishLoading:
      return {
        ...state,
        loading: false,
      };
    case types.uiOpenModal:
      return {
        ...state,
        modalOpen: true,
        phoneCode: action.phoneCode,
        country: action.country,
      };
    case types.uiCloseModal:
      return {
        ...state,
        modalOpen: false,
        phoneCode: null,
        country: null,
      };
    case types.uiInvitationOpenModal:
      return {
        ...state,
        uiInvitationOpenModal: true,
      };
    case types.uiInvitationCloseModal:
      return {
        ...state,
        uiInvitationCloseModal: false,
      };
    case types.uiOpenCalendarModal:
      return {
        ...state,
        CalendarModalOpen: true,
      };
    case types.uiCloseCalendarModal:
      return {
        ...state,
        CalendarModalOpen: false,
      };
    default:
      return state;
  }
};
