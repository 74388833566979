import React, { useEffect, useState } from "react";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import { useForm } from "../../hooks/useForm/useForm";
import { useDispatch, useSelector } from "react-redux";
import { removeError, setError } from "../../actions/ui";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const RegisterScreen = () => {
  useDocumentTitle("Finalizar Registro - Acuarela");
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };
  const dispatch = useDispatch();
  const { msgError, errorType, loading } = useSelector((state) => state.ui);
  const [user, setUser] = useState(null);
  const [finish, setFinish] = useState(false);
  const [sended, setSended] = useState(false);
  const [formRegisterValues, handleRegisterInputChange, reset] = useForm({
    name: user ? user.name : "",
    lastname: user ? user.lastname : "",
    email: user ? user.mail : "",
    password: "",
    repeatPassword: "",
  });
  let { password, repeatPassword, name, lastname, mail } = formRegisterValues;

  const { id } = useParams();

  const getUser = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    await fetch(
      `https://acuarelacore.com/api/acuarelausers/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        reset({
          name: result.name,
          lastname: result.lastname,
          phone: result.phone,
          work_phone: result.work_phone,
          email: result.mail,
          mail: result.mail,
          work_place: result.work_place,
          profession: result.profession,
          password: "",
          repeatPassword: "",
        });
        setUser(result);
      })
      .catch((error) => console.log("error", error));
  };
  const isFormValid = () => {
    if (password.length === 0) {
      dispatch(setError("*La contraseña es obligatoria", "passwordRequired"));
      return false;
    } else if (password.length < 6) {
      dispatch(
        setError("*La contraseña es demasiado débil", "passwordRequired")
      );
      return false;
    } else if (password !== repeatPassword) {
      dispatch(setError("*Las contraseñas no coinciden", "passwordNotSame"));
      return false;
    }
    dispatch(removeError());
    return true;
  };
  const sendFinalEmail = async (name, daycare, pass, email) => {
    let baseUrl = `https://bilingualchildcaretraining.com/s/sendFinalEmail/?name=${name}&daycare=${daycare}&pass=${pass}&email=${email}`;

    await fetch(baseUrl, {
      method: "GET",
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("Invitation no sended ", error));
  };
  useEffect(() => {
    if (!user) {
      getUser();
    }
  });

  const handleRegister = async (e) => {
    setSended(true);
    e.preventDefault();
    if (isFormValid()) {
      if (user) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          name,
          lastname,
          mail,
          email: mail,
          pass: password,
          password,
          repeatPassword,
        });

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `https://acuarelacore.com/api/acuarelausers/${user.id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (result) => {
            console.log({ handleRegister: result });
            var myHeaders2 = new Headers();
            myHeaders2.append("Content-Type", "application/json");

            var raw2 = JSON.stringify({
              mail,
              email: mail,
              pass: password,
            });

            var requestOptions2 = {
              method: "PUT",
              headers: myHeaders2,
              body: raw2,
              redirect: "follow",
            };

            fetch(
              "https://acuarelacore.com/api/acuarelausers/change/password",
              requestOptions2
            )
              .then((response) => response.json())
              .then(async (result) => {
                console.log({ change: result });
                await sendFinalEmail(name, user.daycare.name, password, mail);
                setSended(false);
                setFinish(true);
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) => console.log("error", error));
      }
    }
  };

  return (
    <div className="register">
      <div className="left" style={leftStyle}></div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        {finish && (
          <form
            className="sended"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Cuenta creada</h2>
            <p>
              Has creado tu cuenta exitosamente, pronto recibirás un correo de
              confirmación y links para descargar la aplicación para estar más
              cerca de tu daycare
            </p>
            <a href="https://acuarela.app/" className="btn fill">
              Finalizar
            </a>
          </form>
        )}
        {!finish && user && (
          <form onSubmit={handleRegister} autoComplete="off">
            <h2>Crea tu cuenta</h2>
            <span>
              <label>Nombres:</label>
              <input
                name="name"
                id="name"
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={handleRegisterInputChange}
              />
            </span>
            <span>
              <label>Apellidos:</label>
              <input
                name="lastname"
                id="lastname"
                type="text"
                placeholder="Apellido"
                value={lastname}
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordRequired" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <span>
              <label>Correo electrónico:</label>
              <input
                name="mail"
                id="mail"
                type="text"
                placeholder="Correo electrónico"
                value={mail}
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordRequired" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <span>
              <label>Contraseña:</label>
              <input
                name="password"
                id="password"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordRequired" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <span>
              <label>Repetir contraseña:</label>
              <input
                name="repeatPassword"
                id="repeatPassword"
                type="password"
                placeholder="Repetir contraseña"
                value={repeatPassword}
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordNotSame" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <input type="hidden" value={id} />
            <button type="submit" className="btn fill" disabled={loading}>
              {sended ? "Registrando..." : "Registrar"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
