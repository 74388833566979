import { types } from "../types/types";

export const setError = (err, errorType) => ({
  type: types.uiSetError,
  payload: err,
  errorType: errorType,
});
export const removeError = () => ({
  type: types.uiRemoveError,
});
export const startLoading = () => ({
  type: types.uiStartLoading,
});
export const finishLoading = () => ({
  type: types.uiFinishLoading,
});
export const uiOpenModal = (phoneCode, country) => ({
  type: types.uiOpenModal,
  phoneCode: phoneCode,
  country: country,
});
export const uiCloseModal = () => ({
  type: types.uiCloseModal,
});
export const uiOpenCalendarModal = () => ({
  type: types.uiOpenCalendarModal,
});
export const uiCloseCalendarModal = () => ({
  type: types.uiCloseCalendarModal,
});
export const uiInvitationOpenModal = () => ({
  type: types.uiInvitationOpenModal,
});
export const uiInvitationCloseModal = () => ({
  type: types.uiInvitationCloseModal,
});
