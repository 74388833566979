import React from "react";

import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { uiCloseModal } from "../../actions/ui";
import { useForm } from "../../hooks/useForm/useForm";
import arrow from "././../../assets/images/flechas/Flecha_izquierda.svg";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

export const BoxesModal = (props) => {
  const { state } = props;

  const dispatch = useDispatch();

  const [formValues, handleInputChange] = useForm();

  const { code1, code2, code3, code4 } = formValues;

  const closeModal = () => {
    dispatch(uiCloseModal());
  };

  const verifyCodeSMS = () => {
    if (`${state.data.code}` === `${code1}${code2}${code3}${code4}`) {
      //dispatch(startRegister("-1", password, code, indPhone));
      dispatch(closeModal());
    }
  };

  const keytab = (event) => {
    let element = event.nativeEvent.srcElement.nextElementSibling;
    if (element == null) {
      verifyCodeSMS();
    } else {
      element.focus();
    }
  };

  return (
    <Modal
      isOpen={state.show}
      style={customStyles}
      className="modal"
      overlayClassName="modal-fondo"
      closeTimeoutMS={20000}
    >
      <button className="backArrow" type="button" onClick={closeModal}>
        <img src={arrow} alt="back" width="32" height="32" />
      </button>
      <h2>Registro</h2>
      <form onSubmit={verifyCodeSMS} id="formCode">
        <div className="code-inputs">
          <input
            type="tel"
            id="code1"
            name="code1"
            onKeyUp={keytab}
            onChange={handleInputChange}
            autoFocus
          />
          <input
            type="tel"
            id="code2"
            name="code2"
            onKeyUp={keytab}
            onChange={handleInputChange}
          />
          <input
            type="tel"
            id="code3"
            name="code3"
            onKeyUp={keytab}
            onChange={handleInputChange}
          />
          <input
            type="tel"
            id="code4"
            name="code4"
            onKeyUp={keytab}
            onChange={handleInputChange}
          />
        </div>
      </form>
      <button className="resendCode">Reenviar PIN</button>
      <h5>Verificación de cuenta</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
    </Modal>
  );
};
