import React, { useState } from "react";
import { Link } from "react-router-dom";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import arrow from "././../../assets/images/flechas/Flecha_izquierda.svg";
import { useForm } from "../../hooks/useForm/useForm";
import { useDispatch, useSelector } from "react-redux";
import { removeError, setError } from "../../actions/ui";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const NewPasswordScreen = () => {
  useDocumentTitle("Nueva contraseña - Acuarela");
  const { token } = useParams();
  const { addToast } = useToasts();

  const leftStyle = {
    backgroundImage: `url(${bg})`,
  };

  const dispatch = useDispatch();
  const { msgError, errorType } = useSelector((state) => state.ui);

  const [formRegisterValues, handleRegisterInputChange] = useForm({
    password: "",
    repeatPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  let { password, repeatPassword } = formRegisterValues;

  const isFormValid = () => {
    if (password.length === 0) {
      dispatch(setError("*La contraseña es obligatoria", "passwordRequired"));
      return false;
    } else if (password.length < 6) {
      dispatch(
        setError(
          "*La contraseña debe tener mínimo 6 caracteres",
          "passwordRequired"
        )
      );
      return false;
    } else if (password !== repeatPassword) {
      dispatch(setError("*Las contraseñas no coinciden", "passwordNotSame"));
      return false;
    }
    dispatch(removeError());
    return true;
  };

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isFormValid()) {
      fetch(
        `https://acuarelacore.com/api/acuarelauser/change-password/${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: password }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.msg === "User password change.") {
            setLoading(false);
            addToast("La contraseña se cambio exitosamente", {
              appearance: "success",
            });
            setSended(true);
            // return props.push("/auth/home");
          } else {
            console.log("Fallo no se puede proseguir");
            setLoading(false);
            addToast("La contraseña no se pudo actualizar, token inválido", {
              appearance: "error",
            });
          }
        });
    }
  };

  return (
    <div className="login">
      <div className="left" style={leftStyle}>
        <div className="txt">
          <h1>Ayudamos a los daycares a cuidar el futuro</h1>
          <p>
            Reconocemos que la industria del cuidado infantil necesita un
            software que sea amigable y fácil de usar, y al mismo tiempo lo
            suficientemente poderoso como para ayudar a los propietarios y
            operadores de cuidado infantil a manejar las muchas
            responsabilidades y tareas que surgen cada día.
          </p>
          <button className="btn noFill white">Ver más</button>
        </div>
      </div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        {!sended && (
          <form onSubmit={changePassword} autoComplete="off">
            <Link to="/auth/recovery-password" className="backArrow">
              <img src={arrow} alt="back" width="32" height="32" />
            </Link>
            <h2>Ingresa tu nueva contraseña</h2>
            <span>
              <label>Nueva contraseña</label>
              <input
                id="password"
                name="password"
                placeholder="Contraseña"
                type="password"
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordRequired" && (
                <small className="error">{msgError}</small>
              )}
              {errorType === "passwordNotSame" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <span>
              <label>Repite tu nueva contraseña</label>
              <input
                id="repeatPassword"
                name="repeatPassword"
                placeholder="Contraseña"
                type="password"
                onChange={handleRegisterInputChange}
              />
              {errorType === "passwordNotSame" && (
                <small className="error">{msgError}</small>
              )}
            </span>
            <Link to="/auth/login">Cancelar</Link>
            <button type="submit" className="btn fill">
              {loading ? "Cambiando..." : "Cambiar contraseña"}
            </button>
          </form>
        )}
        {sended && (
          <form className="sended">
            <h2>La contraseña fue actualizada exitosamente</h2>
            <p>
              Puedes volver a la aplicacion e ingresar a la plataforma, con la
              nueva contraseña ingresada.
            </p>
            <a href="https://acuarela.app/" className="btn fill">
              Ir al sitio web
            </a>
          </form>
        )}
      </div>
    </div>
  );
};
