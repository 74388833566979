import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { AppRouter } from "./routes/AppRouter";
import { store } from "./store/store";
import { LoadingScreen } from "./components/preloader/LoadingScreen";
import { ToastProvider } from "react-toast-notifications";
export const AdminApp = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <ToastProvider>
      <Provider store={store}>
        {loading === false ? <AppRouter /> : <LoadingScreen />}
      </Provider>
    </ToastProvider>
  );
};
